<script lang="ts" setup>
const props = defineProps<{
	itemSection: number;
}>();

const { pageData, yourPick } = useHomePage();
const isGuest = useIsGuest();
const { select } = useGamesState();

const games = computed(() => pageData.value.schemeSections[props.itemSection] || []);
</script>
<template>
	<template v-for="(game, index) in games" :key="index">
		<div v-if="game?.slug === 'last' && !isGuest">
			<OLazyComponent id="yourPickGames" :height="368">
				<LazyOHomeGamesSlider title="your picks" :games="yourPick" route="/issues/favorites/" :is-guest="isGuest" />
			</OLazyComponent>
		</div>
		<OLazyComponent
			v-else-if="
				pageData?.games[camelCase(game?.slug ?? '')]?.length && pageData?.games[camelCase(game?.slug ?? '')]?.length > 0
			"
			:id="`home-game-${game?.slug}`"
			:height="626"
		>
			<LazyOHomeGamesSlider
				:title="pageData?.gamesInfo[camelCase(game?.slug ?? '')]?.title || ''"
				:games="select(pageData?.games[camelCase(game?.slug ?? '')])"
				:route="`/issues/${game?.slug}/`"
				:is-guest="isGuest"
				:isSection="true"
				isDynamic
			/>
		</OLazyComponent>
	</template>
</template>
